import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import * as AOS from 'aos';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { SEOService } from 'src/app/seo.service';

@Component({
  selector: 'app-problems-we-are-solving',
  templateUrl: './problems-we-are-solving.component.html',
  styleUrls: ['./problems-we-are-solving.component.scss']
})
export class ProblemsWeAreSolvingComponent implements OnInit {
  scrollToGettingStarted() {
    
    const gettingStartedElement = document.getElementById('gettingStarted');
    if (gettingStartedElement) {
        const viewportHeight = window.innerHeight;
        const elementHeight = gettingStartedElement.clientHeight;
        const yOffset = (viewportHeight - elementHeight) / 2;
        const scrollOptions: ScrollToOptions = {
        top: gettingStartedElement.offsetTop - yOffset,
        behavior: 'smooth',
      };
        window.scrollTo(scrollOptions);
      //gettingStartedElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  constructor(private titleService: Title, private meta: Meta, private seoService: SEOService, private renderer2: Renderer2, @Inject(DOCUMENT) private _document: Document) {
    /*
      * Cannonical tag
     */
   
  }

  ngOnInit() {
   // this.meta.addTag({rel: 'canonical', content: 'https://www.dox2u.com/problems-we-are-solving'};
   this.createLinkForCanonicalURL(); /** to add canonical tags */
    let scriptBreadcrumbListVar = this.renderer2.createElement('script');
    scriptBreadcrumbListVar.type = `application/ld+json`;
    scriptBreadcrumbListVar.text = `{
      "@context": "https://schema.org/", 
    "@type": "BreadcrumbList", 
    "itemListElement": [{
      "@type": "ListItem", 
      "position": 1, 
      "name": "Home page",
      "item": "https://dox2u.com/"  
    },{
      "@type": "ListItem", 
      "position": 2, 
      "name": "Problems we are solving page",
      "item": "https://www.dox2u.com/problems-we-are-solving"  
    }]
      }`
    
    this.renderer2.appendChild(this._document.body, scriptBreadcrumbListVar);
    if (typeof window !== 'undefined') {
      AOS.init(); /* for image animation effect */
    }
    this.titleService.setTitle('Problems Solved by Document Management System | dox2U, Check out all common problems solved by Document Management System (DMS) offered by dox2U. Time to go paperless with dox2U!')/** For Page Title */
  }

  /**
   * Method to add cannomical tags dynamically
   */
  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }
}

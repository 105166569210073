import { Component, OnInit, ElementRef, Renderer2, ViewChild, Inject } from '@angular/core';
import * as AOS from 'aos';
import { imgContent, imgContent1, imgContent2, imgContentH} from 'src/constants/home-content';
import { EmailService } from 'src/app/email.service';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { SEOService } from 'src/app/seo.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit{
  @ViewChild('gettingstarted')
  gettingStartedElement!: ElementRef;

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    center: true,
   // navSpeed: 600,
    //autoplay: false,
    //autoplayTimeout: 1000,
    autoplay: true,
    responsive: {
      0: {
        items: 1 
      },
      400: {
        items: 1
      },
      560: {
        items: 3
      },
      1000: {
        items: 3
      }
    },
  }
 
  constructor(private el: ElementRef, private seoService: SEOService,  private meta: Meta, private renderer2: Renderer2, @Inject(DOCUMENT) private _document: Document, private renderer: Renderer2, private emailService: EmailService, private titleService: Title) {}


  title = 'dox2u';
  /*
  home page right text path
  */
  readonly imgContent: typeof imgContent =  imgContent;
  readonly imgContent1: typeof imgContent1 =  imgContent1;
  readonly imgContent2: typeof imgContent2 =  imgContent2;
  readonly imgContentH: typeof imgContentH = imgContentH;

  /*
  home page image path
  */
  imagePathLeft: string = '../assets/Image/Saas.webp';
  imagePathRight: string = '../assets/Image/do-it-yourself.webp';
  secureImgPath: string= '../../../assets/Image/dox2u_home_encryption.svg';
  imagePathHero: string='../../../assets/Image/homepage/dox2u_home_DMS.svg';

  ngOnInit() {
    // this.meta.addTag({rel: 'canonical', content: 'https://www.dox2u.com'});
    if (typeof window !== 'undefined') {
      AOS.init(); /* for image animation effect */
    }
      
      this.titleService.setTitle('Document Management System (DMS) Software - dox2U'); /** For Page Title */
      this.createLinkForCanonicalURL(); /** to add canonical tags */
      let scriptOrg = this.renderer2.createElement('script');
      scriptOrg.type = `application/ld+json`;
      scriptOrg.text = `{
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "dox2U",
        "url": "https://dox2u.com",
        "logo": "https://www.dox2u.com/assets/Image/logo.png",
        "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+918448857421",
          "contactType": "customer service",
          "areaServed": "IN",
          "availableLanguage": ["en","Hindi"]
        },
        "sameAs": [
          "https://www.facebook.com/dox2u",
          "https://twitter.com/Dox2U",
          "https://www.instagram.com/dox2U",
          "https://www.youtube.com/@dox2u",
          "https://www.linkedin.com/company/dox-2u/",
          "https://dox2u.com"
        ]
      }`

      let scriptBusi = this.renderer2.createElement('script');
      scriptBusi.type = `application/ld+json`;
      scriptBusi.text = `{
        "@context": "https://schema.org",
        "@type": "ProfessionalService",
        "name": "dox2U",
        "image": "https://www.dox2u.com/assets/Image/homepage/dox2u_home_DMS.svg",
        "@id": "https://www.dox2u.com",
        "url": "https://dox2u.com",
        "telephone": "+918448857421",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Emaar Digital Greens, 18th Floor, Tower-B, Baharampur Naya, Sector 61",
          "addressLocality": "Gurugram",
          "postalCode": "122102",
          "addressCountry": "IN"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": 28.4145742,
          "longitude": 77.0939932
        },
        "openingHoursSpecification": {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "opens": "09:30",
          "closes": "19:00"
        },
        "sameAs": [
          "https://www.facebook.com/dox2u",
          "https://twitter.com/Dox2U",
          "https://www.instagram.com/dox2U",
          "https://www.youtube.com/@dox2u",
          "https://www.linkedin.com/company/dox-2u/",
          "https://dox2u.com"
        ] 
      }`

      let scriptAppli = this.renderer2.createElement('script');
      scriptAppli.type = `application/ld+json`;
      scriptAppli.text = `{
        "@context": "https://schema.org/", 
        "@type": "Product", 
        "name": "dox2U",
        "image": "https://www.dox2u.com/assets/Image/homepage/dox2u_home_DMS.svg",
        "description": "dox2U is a cloud-based document management system (DMS) that helps teams go digital and paperless. It's a subscription-based SaaS DMS that creates a single source of truth for all documented information.",
        "brand": {
            "@type": "Brand",
            "name": "dox2U"
          },
        "offers": {
          "@type": "AggregateOffer",
          "url": "",
          "priceCurrency": "INR",
          "lowPrice": "292"
        }

      }`

      
      let scriptHomePage = this.renderer2.createElement('script');
      scriptHomePage.type = `application/ld+json`;
      scriptHomePage.text = `{
        "@context": "https://schema.org/", 
            "@type": "BreadcrumbList", 
            "itemListElement": [{
              "@type": "ListItem", 
              "position": 1, 
              "name": "Home",
              "item": "https://dox2u.com/"  
            },{
              "@type": "ListItem", 
              "position": 2, 
              "name": "Product",
              "item": "https://dox2u.com/tally"  
            },{
              "@type": "ListItem", 
              "position": 3, 
              "name": "Pricing",
              "item": "https://dox2u.com/pricing"  
            },{
              "@type": "ListItem", 
              "position": 4, 
              "name": "Blogs",
              "item": "https://blog.dox2u.com/"  
            },{
              "@type": "ListItem", 
              "position": 5, 
              "name": "Contact us",
              "item": "https://www.dox2u.com/contact-us"  
            }]
      }`

      let scriptWebsite = this.renderer2.createElement('script');
      scriptWebsite.type = `application/ld+json`;
      scriptWebsite.text = `{
        "@context": "https://schema.org/",
          "@type": "WebSite",
          "name": "dox2U",
          "url": "https://dox2u.com/",
          "potentialAction": {
            "@type": "SearchAction",
            "target": "https://www.dox2u.com/find-workspace{search_term_string}",
            "query-input": "required name=search_term_string"
          }
      }`
    
      this.renderer2.appendChild(this._document.body, scriptOrg);
      this.renderer2.appendChild(this._document.body, scriptBusi);
      this.renderer2.appendChild(this._document.body, scriptAppli);
      this.renderer2.appendChild(this._document.body, scriptHomePage);
      this.renderer2.appendChild(this._document.body, scriptWebsite);
    } 
    
    /**
   * Method to add cannomical tags dynamically
   */

    createLinkForCanonicalURL() {
      this.seoService.createLinkForCanonicalURL();
    }
}




import { Component, Input } from '@angular/core';
import { productSubmenu } from 'src/app/interfaces/interfaces';
import { productHanle } from 'src/constants/product-content';

@Component({
  selector: 'app-product-left-text-with-button',
  templateUrl: './product-left-text-with-button.component.html',
  styleUrls: ['./product-left-text-with-button.component.scss']
})
export class ProductLeftTextWithButtonComponent {

  @Input() imgSrc:string=" ";
  @Input() contentH!:productSubmenu;

  scrollToGettingStarted(event: Event) {
    const gettingStartedElement = document.getElementById('gettingStarted');
    if (gettingStartedElement) {
      const viewportHeight = window.innerHeight;
            const elementHeight = gettingStartedElement.clientHeight;
            const yOffset = (viewportHeight - elementHeight) / 2;
          const scrollOptions: ScrollToOptions = {
            top: gettingStartedElement.offsetTop - yOffset,
            behavior: 'smooth',
          };
          window.scrollTo(scrollOptions);
      //gettingStartedElement.scrollIntoView({ behavior: 'smooth' });
    }

    else {
      
    const waitingFormElem = document.getElementById('formtally');
    console.log("tallyBackup", waitingFormElem);
    if(waitingFormElem) {
      const viewportHeight = window.innerHeight;
      const elementHeight = waitingFormElem.clientHeight;
      const yOffset = (viewportHeight - elementHeight) / 2;
    const scrollOptions: ScrollToOptions = {
      top: waitingFormElem.offsetTop - yOffset,
      behavior: 'smooth',
    };
    window.scrollTo(scrollOptions);
    }
    }


  }
}

import { Component, ElementRef, HostListener, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { EmailService } from 'src/app/email.service';
import { connetedInformation, convinience, docketsHeroText, flexibility, tracability } from 'src/constants/product-content';
import { SEOService } from 'src/app/seo.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ZohoService } from 'src/app/zoho.service';
import { environment } from 'src/environments/environment';
import { CustomerIOService } from 'src/app/customer-io.service';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-dockets',
  templateUrl: './dockets.component.html',
  styleUrls: ['./dockets.component.scss']
})
export class DocketsComponent implements OnInit {
  @ViewChild('myVideo') videoElement!: ElementRef<HTMLVideoElement>;
  ngOnInit(): void {
    this.createLinkForCanonicalURL() /** to add canonical tags */
    //this.meta.addTag({rel: 'canonical', content: 'https://www.dox2u.com/dockets'});
    this.emailService.headerOff.next(true);
    this.meta.addTag({rel: 'canonical', content: 'https://www.dox2u.com/dockets'});
    let scriptBreadcrumbList1 = this.renderer2.createElement('script');
    scriptBreadcrumbList1.type = `application/ld+json`;
    scriptBreadcrumbList1.text = `{
      "@context": "https://schema.org/", 
    "@type": "BreadcrumbList", 
    "itemListElement": [{
      "@type": "ListItem", 
      "position": 1, 
      "name": "Home page",
      "item": "https://dox2u.com/"  
    },{
      "@type": "ListItem", 
      "position": 2, 
      "name": "Dockets page",
      "item": "https://www.dox2u.com/dockets"  
    }]
      }`
    
    this.renderer2.appendChild(this._document.body, scriptBreadcrumbList1);

    this.titleService.setTitle('Docket - File Cabinet - Document Management Software | dox2U","This Virtual File Cabinet Document Management Software System provided by dox2U, lets the users to search and retrieve documents conveniently.')/** For Page Title */
  }

  constructor(private meta: Meta, private fb: FormBuilder, public emailService: EmailService, private customerIOService: CustomerIOService, private zohoService: ZohoService, private partnerfb: FormBuilder, private seoService: SEOService, private titleService: Title, private renderer2: Renderer2, @Inject(DOCUMENT) private _document: Document){

    this.tallyPrimeForm = this.fb.group({
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      companyname:['', [Validators.required]],
      email: ['', [Validators.required, Validators.email,
        Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),]],
        phone: ['', [Validators.required, Validators.maxLength(15)]],
    });
        
  }
  readonly docketsHeroText: typeof docketsHeroText = docketsHeroText;
  readonly convinience: typeof convinience = convinience;
  readonly flexibility: typeof flexibility = flexibility;
  readonly tracability: typeof tracability = tracability;
  readonly connetedInformation: typeof connetedInformation = connetedInformation;
  convenience_img:string="../../../../assets/Image/dockets/dox2u_dockets_convenience.svg"
  docket_organize_img:string="../../../../assets/Image/dockets/dox2u_dockets_organize.svg"
  docket_docuemt_img:string="../../../../assets/Image/dockets/dox2u_dockets_document.svg"
  docketInfo_img:string="../../../../assets/Image/dockets/dox2u_dockets_info.svg"
  docketsHero_img:string="../../../../assets/Image/dockets/dox2u_dockets_banner.svg"
  userInteracted: boolean = false;
  partnerFormShow: boolean = true;
  firstNamePValidDiv: boolean = false;
  lastNamePValidDiv: boolean = false;
  organizationPValidDiv: boolean = false;
  tallyemailPValidDiv: boolean = false;
  phonePValidDiv: boolean = false;
  phonePValidationDiv: boolean = false;
  countryPValidDiv: boolean = false;
  tallyemailPEmptyDiv: boolean = false;
  tallyemailPInvalidDiv: boolean = false;
  partnerFormSubmitted: boolean = false;
  tallyPrimeForm: FormGroup;
  firstNameValidDivTP: boolean = false;
  lastNameValidDivTP: boolean = false;
  emailValidDivEmptyTP: boolean = false;
  emailValidDivInvalidTP: boolean = false;
  phoneValidDivTP: boolean = false;
  phoneValidationCheckDivTP: boolean = false;
  companyNameValidDivTP: boolean = false;
  countryNameTP: string = 'India';
  dialCodeTP: Number = 91;
  contactNumberTP: any;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  separateDialCode!: false;

   /**
   * Method to add cannomical tags dynamically
   */

   createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }

  whatsappIntegrationImg: string=" ";
  videoURL: string = 'https://www.dox2u.com/e-docs/Dockets_video_v2.mp4'
  
  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    const isVideoInViewport = this.isElementInViewport(this.videoElement.nativeElement);
    if (!isVideoInViewport) {     
      this.videoElement.nativeElement.pause();  
    }
  }

  private isElementInViewport(element: HTMLElement): boolean {
    const rect = element.getBoundingClientRect();
    
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
    
  }


  scrollToGettingStarted() {
    
    const gettingStartedElement = document.getElementById('gettingStarted');
    if (gettingStartedElement) {
        const viewportHeight = window.innerHeight;
        const elementHeight = gettingStartedElement.clientHeight;
        const yOffset = (viewportHeight - elementHeight) / 2;
        const scrollOptions: ScrollToOptions = {
        top: gettingStartedElement.offsetTop - yOffset + 200,
        behavior: 'smooth',
      };
        window.scrollTo(scrollOptions);
      //gettingStartedElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  pauseVideo() {
    this.userInteracted = !this.userInteracted;
  }

  onCountryChangeTP(event: any) {
    this.countryNameTP = event.name;
    this.dialCodeTP = event.dialCode;
    this.contactNumberTP = event.e164Number;
    console.log('Selected Country ISO:', event.iso2);
    console.log('Selected Country Name:', this.countryNameTP);
    console.log('Selected Country Dial Code:', this.dialCodeTP);
  }

  resetFirstNameDivTP(event: Event) {
    this.firstNameValidDivTP = false;
  }

  resetLastNameDivTP(event: Event) {
    this.lastNameValidDivTP = false;
  }

  resetEmailDivTP(event: Event) {
    this.emailValidDivEmptyTP = false;
    this.emailValidDivInvalidTP = false;
  }

  resetPhoneDivTP(event: Event) {
    this.phoneValidDivTP = false;
    this.phoneValidationCheckDivTP = false;
  }

  resetCompanyNameDivTP(event: Event) {
    this.companyNameValidDivTP = false;
  }


  onSubmitTallyPrime() {
    if(this.tallyPrimeForm.get('firstname')?.hasError('required')) {
      this.firstNameValidDivTP = true;
    }
  
    if(this.tallyPrimeForm.get('lastname')?.hasError('required')) {
      this.lastNameValidDivTP = true;
    }

    if(this.tallyPrimeForm.get('companyname')?.hasError('required')) {
      this.companyNameValidDivTP = true;
    }
  
    if(this.tallyPrimeForm.get('email')?.hasError('required')) {
      this.emailValidDivEmptyTP = true;
    }
  
    if(this.tallyPrimeForm.get('email')?.hasError('email')) {
      this.emailValidDivInvalidTP = true;
    }
  
    if(this.tallyPrimeForm.get('email')?.hasError('pattern')) {
      this.emailValidDivInvalidTP = true;
    }

    if(this.tallyPrimeForm.controls['phone'].invalid) {
      this.phoneValidationCheckDivTP = true;
      event?.preventDefault();
     }
  
    if(this.tallyPrimeForm.get('phone')?.hasError('required')) {
      this.phoneValidDivTP = true;
      this.phoneValidationCheckDivTP = false;

      
    }
  
    //this.checkPhonenoValidation();
    // let phoneNumber = this.tallyPrimeForm.get('phone')?.value;
    //   let countryCode = (localStorage.getItem('countryCode'))?.toUpperCase(); 
    //   let isPhonenoValid = this.validatePhoneNumber(phoneNumber, this.dialCode);
  
    //  console.log("isPhonenoValid", isPhonenoValid)
  
     
  
    // let countryName = localStorage.getItem("CountryName");
    // let dialCode = localStorage.getItem("DialCode");
    // let contactNumber = '';
  
      if (this.tallyPrimeForm.valid && !this.phoneValidationCheckDivTP) {
       
          this.partnerFormSubmitted = true;
          this.partnerFormShow = false;
          const phoneValueTP = this.tallyPrimeForm.value.phone;
          //  contactNumber = `+${this.dialCode}${phoneValue}`;
          this.contactNumberTP = phoneValueTP.internationalNumber;
            console.log("contactNumber", this.contactNumberTP)
            console.log("dialCode", this.dialCodeTP)
            console.log("phoneValue", this.contactNumberTP)
            console.log("countryName", this.countryNameTP)
            const useremail = this.tallyPrimeForm.value.email;
            const planID = '46'
  
          /*
          payload for zoho service
        */
          const firstName = this.tallyPrimeForm.value.firstname;
          const lastName = this.tallyPrimeForm.value.lastname;
          const fullName = `${firstName} ${lastName}`;
          const email = this.tallyPrimeForm.value.email;
          const WS_SubscriptionStatus = "";
          const leadSource = 'WS-Dockets-StartTrial';
          const address1 = '';
          const address2 = '';
          const phoneNo = this.contactNumberTP;
          const imgContent_security = '';
          const state = '';
          const country = this.countryNameTP;
          const orgName = this.tallyPrimeForm.value.companyname;
          const companySize = '';
          const jobTitle = '';
          const salutation = '';
          const message1 = '';
          const message2 = '';
          window.open(`https://app.dox2u.com/onboarding/create-workspace/${planID}?email=${useremail}&username=${fullName}`, '_blank');
          let completeURL = `https://app.dox2u.com/onboarding/create-workspace/${planID}?email=${useremail}&username=${fullName}`
          console.log("completeURL", completeURL)
          /*
            function to send data to zoho service
          */
  
          this.zohoService.sendUserData(firstName, lastName, fullName, 
            email, 
            WS_SubscriptionStatus, 
            leadSource, 
            address1,
            address2,
            phoneNo,
            imgContent_security,
            state,
            country,
            orgName,
            companySize,
            jobTitle,
            salutation,
            message1,
            message2)
          .subscribe(
            (response) => {
              console.log('zoho lead sent:', response);
            },
            error => {
              console.error('Email Error:', error);
            }
          );
  
          /*
          payload for email service
          */ 
          const recipientEmail = this.tallyPrimeForm.value.email;
          const messageId = environment.PartnerFormsMessageID;
          const lastname = this.tallyPrimeForm.value.lastname;
          const firstname = this.tallyPrimeForm.value.firstname;
          const ownerFullName = firstname+' '+lastname;
          console.log ('fullname', ownerFullName);
          
  
          const messageData = { First_Name: this.tallyPrimeForm.value.firstname,  
                                ownernName: ownerFullName,
                                ownerEmail: this.tallyPrimeForm.value.email};
  
            /*
            function to send data to email service
          */
          this.emailService.sendEmail(recipientEmail, messageId, messageData, email)
            .subscribe(
              (response) => {
                console.log('Email sent:', response);
              },
              (error) => {
                console.error('Email Error:', error);
              }
            );
  
              
          /*
            function to send data to customer.io
          */
              this.customerIOService.updateUserLeadSource(email, leadSource)
                .subscribe(
                  (response) => {
                    console.log('User lead source updated successfully:', response);
                  },
                  (error) => {
                    console.error('Error updating user lead source:', error);
                  }
                );
        }
  
        
      }


      sendDemoLead() {
        if(this.tallyPrimeForm.get('firstname')?.hasError('required')) {
          this.firstNameValidDivTP = true;
        }
      
        if(this.tallyPrimeForm.get('lastname')?.hasError('required')) {
          this.lastNameValidDivTP = true;
        }
    
        if(this.tallyPrimeForm.get('companyname')?.hasError('required')) {
          this.companyNameValidDivTP = true;
        }
      
        if(this.tallyPrimeForm.get('email')?.hasError('required')) {
          this.emailValidDivEmptyTP = true;
        }
      
        if(this.tallyPrimeForm.get('email')?.hasError('email')) {
          this.emailValidDivInvalidTP = true;
        }
      
        if(this.tallyPrimeForm.get('email')?.hasError('pattern')) {
          this.emailValidDivInvalidTP = true;
        }
    
        if(this.tallyPrimeForm.controls['phone'].invalid) {
          this.phoneValidationCheckDivTP = true;
          event?.preventDefault();
         }
      
        if(this.tallyPrimeForm.get('phone')?.hasError('required')) {
          this.phoneValidDivTP = true;
          this.phoneValidationCheckDivTP = false;
    
          
        }
      
          if (this.tallyPrimeForm.valid && !this.phoneValidationCheckDivTP) {

              this.partnerFormSubmitted = true;
              this.partnerFormShow = false;
              const phoneValueTP = this.tallyPrimeForm.value.phone;
              //  contactNumber = `+${this.dialCode}${phoneValue}`;
              this.contactNumberTP = phoneValueTP.internationalNumber;
                console.log("contactNumber", this.contactNumberTP)
                console.log("dialCode", this.dialCodeTP)
                console.log("phoneValue", this.contactNumberTP)
                console.log("countryName", this.countryNameTP)
                
              /*
              payload for zoho service
            */
              const firstName = this.tallyPrimeForm.value.firstname;
              const lastName = this.tallyPrimeForm.value.lastname;
              const fullName = `${firstName} ${lastName}`;
              const email = this.tallyPrimeForm.value.email;
              const WS_SubscriptionStatus = "";
              const leadSource = 'WS-Dockets-Demo';
              const address1 = '';
              const address2 = '';
              const phoneNo = this.contactNumberTP;
              const imgContent_security = '';
              const state = '';
              const country = this.countryNameTP;
              const orgName = this.tallyPrimeForm.value.companyname;
              const companySize = '';
              const jobTitle = '';
              const salutation = '';
              const message1 = '';
              const message2 = '';
              window.open(`https://calendly.com/dox2u-dms/dockets-demo-ws?name=${firstName}%20${lastName}&email=${email}`, '_blank');
              /*
                function to send data to zoho service
              */
      
              this.zohoService.sendUserData(firstName, lastName, fullName, 
                email, 
                WS_SubscriptionStatus, 
                leadSource, 
                address1,
                address2,
                phoneNo,
                imgContent_security,
                state,
                country,
                orgName,
                companySize,
                jobTitle,
                salutation,
                message1,
                message2)
              .subscribe(
                (response) => {
                  console.log('zoho lead sent:', response);
                },
                error => {
                  console.error('Email Error:', error);
                }
              );
                  
              /*
                function to send data to customer.io
              */
                  this.customerIOService.updateUserLeadSource(email, leadSource)
                    .subscribe(
                      (response) => {
                        console.log('User lead source updated successfully:', response);
                      },
                      (error) => {
                        console.error('Error updating user lead source:', error);
                      }
                    );
            }           
      }
}

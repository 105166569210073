import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomerIOService } from 'src/app/customer-io.service';
import { ZohoService } from 'src/app/zoho.service';

@Component({
  selector: 'app-getting-started',
  templateUrl: './getting-started.component.html',
  styleUrls: ['./getting-started.component.scss']
})
export class GettingStartedComponent {

  emailForm: FormGroup;
  emailInputInvalid: boolean=false;

  constructor(private emailfb: FormBuilder, private zohoService: ZohoService,private customerIOService: CustomerIOService,){
    this.emailForm = this.emailfb.group({
      emailInput : ['', [Validators.required, Validators.email,
        Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),]]
    })
  }

  emailInputReset(event: Event) {
    this.emailInputInvalid = false;
  }

  routeToCreateWS() {
    if(this.emailForm.get('emailInput')?.hasError('required')) {
      this.emailInputInvalid = true;
    }
    if(this.emailForm.get('emailInput')?.hasError('email')) {
      this.emailInputInvalid = true;
    }

    if (this.emailForm.valid) {
      const useremail = this.emailForm.value.emailInput;
      const planID = '47'
     const workspaceUrl = `https://app.dox2u.com/onboarding/create-workspace/${planID}?email=${useremail}`;
      //const workspaceUrl = "https://app.dox2u.com/onboarding/create-workspace/47";
      window.open(workspaceUrl, '_blank');
      console.log("workspaceUrl", workspaceUrl)

      const firstName = '';
        const lastName = 'Null';
        const fullName = '';
        const email = this.emailForm.value.emailInput;
        const orgName = '';
        const phoneNo = '';
        const country = '';
        const WS_SubscriptionStatus = "";
        const leadSource = "WS-CreateWS";
        const address1 = '';
        const address2 = '';        
        const imgContent_security = '';
        const state = '';
        const companySize = '';
        const jobTitle = '';
        const salutation = '';
        const message1 = '';
        const message2 = '';

        this.zohoService.sendUserData(firstName, lastName, fullName, 
          email, 
          WS_SubscriptionStatus, 
          leadSource, 
          address1,
          address2,
          phoneNo,
          imgContent_security,
          state,
          country,
          orgName,
          companySize,
          jobTitle,
          salutation,
          message1,
          message2)
        .subscribe(
          (response) => {
            console.log('zoho lead sent:', response);
          },
          error => {
            console.error('Email Error:', error);
          }
        );

        this.customerIOService.updateUserLeadSource(email, leadSource)
              .subscribe(
                (response) => {
                  console.log('User lead source updated successfully:', response);
                },
                (error) => {
                  console.error('Error updating user lead source:', error);
                }
              );
  }
  }
}

<div class="countdown-timer" id="countdown">
  <p class="mb-0">Launching In</p>
  <div class="time-display">
    <div class="time-unit day">
      <span class="time-number">{{ days }}</span>
      <span class="time-label">Day</span>
    </div>
    <div class="mx-3 mt-2">:</div>
    <div class="time-unit hour">
      <span class="time-number">{{ hours }}</span>
      <span class="time-label">Hour{{ hours !== 1 ? 's' : '' }}</span>
    </div>
    <div class="mx-3 mt-2">:</div>
    <div class="time-unit minute">
      <span class="time-number">{{ minutes }}</span>
      <span class="time-label">Min{{ minutes !== 1 ? 's' : '' }}</span>
    </div>
  </div>
</div>


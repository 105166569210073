import { NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeroComponent } from './shared/hero/hero.component';
import { IntegrateDocumentComponent } from './components/integrate-document/integrate-document.component';
import { HelpBusinessComponent } from './components/help-business/help-business.component';
import { LeftImageRightTextComponent } from './shared/left-image-right-text/left-image-right-text.component';
import { RightImageLeftTextComponent } from './shared/right-image-left-text/right-image-left-text.component';
import { GettingStartedComponent } from './shared/getting-started/getting-started.component';
import { InsertImageDescriptionComponent } from './shared/insert-image-description/insert-image-description.component';
import { NewFeatureComponent } from './shared/new-feature/new-feature.component';
import { EightCardsComponent } from './components/eight-cards/eight-cards.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { PaperPainsComponent } from './paper-pains/paper-pains.component';
import { TestimonialsComponent } from './components/testimonials/testimonials.component';
import { RelationshipBannerComponent } from './components/relationship-banner/relationship-banner.component';
import { Dox2UForTallyComponent } from './components/dox2-u-for-tally/dox2-u-for-tally.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { TallyManagementPluginComponent } from './components/tally-management-plugin/tally-management-plugin.component';
import { PartnersComponent } from './components/partners/partners.component';
import { PricingComponent } from './components/pricing/pricing.component';
import { PricingCardComponent } from './shared/pricing-card/pricing-card.component';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { ZohoService } from './zoho.service';
import { PartnerWithUsComponent } from './shared/partner-with-us/partner-with-us.component';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { environment } from '../environments/environment';
import { FindWorkspaceComponent } from './components/find-workspace/find-workspace.component';
import { FeatureComponent } from './components/product/feature/feature.component';
import { GradientDividerComponent } from './components/gradient-divider/gradient-divider.component';
import { ThreeDotComponent } from './components/three-dot/three-dot.component';
import { DocumentHandlingComponent } from './components/product/document-handling/document-handling.component';
import { ProductLeftTextWithButtonComponent } from './shared/product-left-text-with-button/product-left-text-with-button.component';
import { InitialsAvatarComponent } from './components/initials-avatar/initials-avatar.component';
import { SecurityAndReliabilityComponent } from './components/product/security-and-reliability/security-and-reliability.component';
import { ProductRImageLeftTextComponent } from './shared/product-r-image-left-text/product-r-image-left-text.component';
import { ProductLImageRightTextComponent } from './shared/product-l-image-right-text/product-l-image-right-text.component';
import { MoreFeatureFourCardComponent } from './shared/more-feature-four-card/more-feature-four-card.component';
import { SmartCabinetComponent } from './components/product/smart-cabinet/smart-cabinet.component';
import { TextExtractionComponent } from './components/product/text-extraction/text-extraction.component';
import { GuestAccessComponent } from './components/product/guest-access/guest-access.component';
import { DeepSearchComponent } from './components/product/deep-search/deep-search.component';
import { DocketsComponent } from './components/product/dockets/dockets.component';
import { ProblemsWeAreSolvingComponent } from './components/resources/problems-we-are-solving/problems-we-are-solving.component';
import { WhyChooseDox2UComponent } from './components/resources/why-choose-dox2-u/why-choose-dox2-u.component';
import { BuiltForEveryoneComponent } from './components/resources/built-for-everyone/built-for-everyone.component';
import { ContactusComponent } from './components/contactus/contactus.component';
import { TypewriterDirective } from './directive/typewriter.directive';
import { WidgetPopupComponent } from './shared/widget-popup/widget-popup.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BlogCarouselComponent } from './shared/blog-carousel/blog-carousel.component';
import { DownloadDMSAddOnComponent } from './components/download-dms-add-on/download-dms-add-on.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { Error404Component } from './shared/error404/error404.component';
import { DocumentManagementSystemComponent } from './components/document-management-system/document-management-system.component';
import { LowerCaseUrlSerializer } from '../lower-case-url-serializer'
import {provideClientHydration} from '@angular/platform-browser';
import { Injectable } from '@angular/core';
import { UrlSerializer } from '@angular/router';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { CountdownTimerComponent } from './shared/countdown-timer/countdown-timer.component';
import { TallyBackupComponent } from './components/tally-backup/tally-backup.component';
function _window(): any {
  return window;
}
@Injectable()
export class WindowRef {
 get nativeWindow(): any {
   return _window();
 }
}



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HeroComponent,
    IntegrateDocumentComponent,
    HelpBusinessComponent,
    LeftImageRightTextComponent,
    RightImageLeftTextComponent,
    GettingStartedComponent,
    InsertImageDescriptionComponent,
    NewFeatureComponent,
    EightCardsComponent,
    PaperPainsComponent,
    TestimonialsComponent,
    RelationshipBannerComponent,
    Dox2UForTallyComponent,
    DashboardComponent,
    TallyManagementPluginComponent,
    PartnersComponent,
    PricingComponent,
    PricingCardComponent,
    FeatureComponent,
    GradientDividerComponent,
    ThreeDotComponent,
    DocumentHandlingComponent,
    ProductLeftTextWithButtonComponent,
    SecurityAndReliabilityComponent,
    ProductRImageLeftTextComponent,
    ProductLImageRightTextComponent,
    MoreFeatureFourCardComponent,
    SmartCabinetComponent,
    TextExtractionComponent,
    GuestAccessComponent,
    DeepSearchComponent,
    DocketsComponent,
    ProblemsWeAreSolvingComponent,
    WhyChooseDox2UComponent,
    BuiltForEveryoneComponent,
    ContactusComponent,
    TypewriterDirective,
    DocumentManagementSystemComponent,
    PrivacyPolicyComponent,
    PartnerWithUsComponent,
    FindWorkspaceComponent,
    WidgetPopupComponent,
    BlogCarouselComponent,
    DownloadDMSAddOnComponent,
    Error404Component,
    InitialsAvatarComponent,
    CountdownTimerComponent,
    TallyBackupComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    HttpClientModule,
    HttpClientJsonpModule,
    BrowserAnimationsModule,
    FormsModule,
    CarouselModule, 
    LoggerModule.forRoot({
      serverLogLevel: NgxLoggerLevel.OFF, // Set the server log level
      level: environment.logLevel, // Use the log level from the environment file
      serverLoggingUrl: '/api/logs', // Optional: Set the server logging URL if needed
    }),
  ],
  providers: [ZohoService,  provideClientHydration(), { provide: UrlSerializer, useClass: LowerCaseUrlSerializer }, Meta],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

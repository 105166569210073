import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss']
})
export class CountdownTimerComponent implements OnInit, OnDestroy {
  targetDate: Date = new Date('2024-12-20T12:01:00'); 
  days: number = 0;
  hours: number = 0;
  minutes: number = 0;
  remainingTime: string = '';
  intervalId: any;

  constructor(@Inject(PLATFORM_ID) private platformId: any) {

  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.updateRemainingTime();
    // Update countdown every second
    this.intervalId = setInterval(() => {
      this.updateRemainingTime();
    }, 1000);
  }
    
  }

  ngOnDestroy() {
    // Clear the interval when the component is destroyed
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  updateRemainingTime() {
    const currentTime = new Date();
    const timeDifference = this.targetDate.getTime() - currentTime.getTime();

    if (timeDifference <= 0) {
      let countdownElem = document.getElementById('countdown');
      countdownElem?.classList.add('d-none');
      // If the target time has passed

      this.remainingTime = 'Launch time reached';
      clearInterval(this.intervalId);
      return;
    }

    const totalSeconds = Math.floor(timeDifference / 1000);
    this.days = Math.floor(totalSeconds / (60 * 60 * 24));
    this.hours = Math.floor((totalSeconds % (60 * 60 * 24)) / (60 * 60));
    this.minutes = Math.floor((totalSeconds % (60 * 60)) / 60);

    // Format days, hours, and minutes to always have two digits
    this.days = this.formatTime(this.days);
    this.hours = this.formatTime(this.hours);
    this.minutes = this.formatTime(this.minutes);
  }

  // Helper function to format time with leading zero
  formatTime(time: number): any {
    return time < 10 ? `0${time}` : `${time}`;
  }
}

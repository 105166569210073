<app-countdown-timer></app-countdown-timer>
<section class="workspaces-banner document-handling-banner">
    <app-product-left-text-with-button [imgSrc] = "tallybackup_img" [contentH]="tallybackupHeroText"></app-product-left-text-with-button >
</section>

<section class="download-and-install top-padd bottom-padd common-padd-tallyForm ">
    <div class="container">
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto dms-header-container">
                <p class="dms-header1">What can dox2U do?</p>
                <h3 class="size-fourty mb-3">dox2U: The <span class=underline-highlight1>Best</span> Way to Backup Tally Data</h3>
                <p class="common-heading" style="text-align: center;">Every business relies on Tally for financial records, compliance and operations. But without a proper backup strategy in place, your data is vulnerable </p>
            </div>
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
                <div class="container">
                    <!-- Problem Column -->
                    <div class="column column1">
                      <h2>Problem
                        <div class="sub-h2">
                            Think Your Data is Safe? Think Again
                        </div>
                      </h2>
                      <div class="content-para">
                        <div class="content-para1">
                            <p class="content-heading" >Losing Tally Data</p>
                            <p class="content-text">Hard drive failures, accidental deletions, and viruses can result in irreversible loss of your Tally data</p>
                        </div>
                        <div class="content-para2">
                            <p class="content-heading" >Unprotected Data</p>
                            <p class="content-text">Ransomware, hacking attacks or server crashes can impact the continuity of your business operations</p>
                        </div>
                      </div>
                      
                    </div>
                    
                    <!-- Solution Column -->
                    <div class="column column2">
                      <h2>Solution
                        <div  class="sub-h2">dox2U: Tally Backup for Peace of Mind</div>
                      </h2>
                      <div class="content-para" style="padding-top: 66px; padding-bottom: 66px;">
                        <div class="content-paraSol">
                            <p class="line-rect" ></p>
                            <p class="content-text">Flexible scheduling for auto backups (daily, weekly, monthly)</p>
                        </div>
                        <div class="content-paraSol">
                            <p class="line-rect" ></p>
                            <p class="content-text">Secure offsite storage of encrypted backups (at multiple locations) </p>
                        </div>
                        <div class="content-paraSol">
                            <p class="line-rect" ></p>
                            <p class="content-text">Built-in antivirus detection</p>
                        </div>
                        <div class="content-paraSol">
                            <p class="line-rect" ></p>
                            <p class="content-text">Background uploading of backups without opening Tally</p>
                        </div>
                        <div class="content-paraSol">
                            <p class="line-rect" ></p>
                            <p class="content-text">Optimized storage using compressed zip files for Tally backups</p>
                        </div>
                        <div class="content-paraSol">
                            <p class="line-rect" ></p>
                            <p class="content-text">Auto deletion of unwanted backups</p>
                        </div>
                      </div>

                      
                    </div>
                  </div>
            </div>
        </div>
    </div>
</section>

<section id="formtally" class="top-padd bottom-padd  formtally">
    <div class="container">
        <div class="row tallyBackupContainer">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
                <div class="row d-flex justify-content-between align-items-start" style="row-gap: 20px ">
                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 p-0 d-flex flex-column justify-content-center artopD">
                        <p class="dms-header1">Preview Offer</p>
                        <h3 class="size-fourty mb-3">Secure Your Tally Data Now!</h3>
                        <p class="common-heading">Don’t let data disasters derail your business. Get on our waitlist today and get 30% off on your subscription.</p>
                    </div>
                    <!-- <div class="col-xxl-1 d-xl-none d-xxl-block d-lg-none d-md-none d-sm-none"></div> -->
                    <div class="tallyBackupForm col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 p-0 d-flex flex-column justify-content-center artopD">
                        <div id="tallyDMSshow" *ngIf="waitingFormShow">
                            <p class="head1">Join the Waitlist</p>
                            <form [formGroup]="tallyBackupForm" (ngSubmit)="onSubmitTallyBackup($event)">
                                <div class="d-flex flex-column colmG">
                                    <div class="d-flex form-container w-100">
                                        <div class="d-flex flex-column w-50 input-container">
                                            <div class="nameInput d-flex flex-column form-group">
                                                <label>
                                                    <p class="label-field mb-1 tunset">First Name<span class="text-danger"
                                                        >*</span>
                                                    </p>
                                                </label>
                                                <input class="form-control" formControlName="firstname" maxlength="100" id="firstname" autocomplete="off" name="firstname" placeholder="Enter first name" (keypress)="resetFirstNameDivTP($event)">
                                                
                                                    <!-- <div *ngIf="dmsForm.get('firstname')?.hasError('required') && dmsForm.get('firstname').touched" class="error">This field cannot be empty</div> -->
                                            </div>
                                            <p class="mb-0 meserr text-danger" *ngIf="firstNameValidDivTP">
                                                This field cannot be empty
                                              </p>
                                              
                                            <p id="errfirstname1" class="mb-0  meserr text-danger"></p>
                                        </div>
                                        <div class="d-flex flex-column w-50 input-container">
                                            <div class="nameInput d-flex flex-column form-group">
                                                <label>
                                                    <p class="label-field mb-1 tunset">Last Name<span class="text-danger"
                                                            >*</span></p>
                                                </label>
                                                <input class="form-control" formControlName="lastname" maxlength="100" autocomplete="off" id="lastname" placeholder="Enter last name" name="lastname" (keypress)="resetLastNameDivTP($event)">
                                            </div>
                                            <p class="mb-0 meserr text-danger" *ngIf="lastNameValidDivTP">
                                                This field cannot be empty
                                              </p>
                                            <p id="errlastname1" class="mb-0  meserr"></p>
                                        </div>
                                    </div>
                                    <!-- <div class="d-flex form-container w-100"> -->

                                        <div class="d-flex flex-column w-100 input-container">
                                            <div class="nameInput d-flex flex-column form-group">
                                                <label>
                                                    <p class="label-field mb-1 tunset">Email Address<span
                                                            style="color:red">*</span>
                                                    </p>
                                                </label>
                                                <input class="form-control" autocomplete="off" formControlName="email" id="tallyemail" placeholder="Enter your email address" name="tallyemail" (keypress)="resetEmailDivTP($event)">
                                            </div>
                                            <p class="mb-0 meserr text-danger" *ngIf="emailValidDivEmptyTP">
                                                This field cannot be empty
                                              </p>
                                              <p class="mb-0 meserr text-danger" *ngIf="emailValidDivInvalidTP">
                                                Please enter the valid email
                                              </p>
                                              
                                           
                                        </div>
                                        <div class="d-flex flex-column w-100 input-container">
                                            <div class="nameInput d-flex flex-column form-group">
                                                <label class="phn-label">
                                                    <p class="label-field mb-0 tunset">Phone Number<span class="text-danger"
                                                        >*</span></p>
                                                </label>

                                                <!-- <input name="phone" type="tel" id="phoneC" 
                                                    class="getting-started-input phn-inp inp100 form-control" placeholder="0987654321" formControlName="phone" (input)="resetPhoneDivTP($event)"
                                                    
                                                    maxlength="17"> -->

                                                    <ngx-intl-tel-input class="getting-started-input phn-inp inp100 form-control" (input)="resetPhoneDivTP($event)"
                                                    [cssClass]="'custom'"
                                                  
                                                    [enableAutoCountrySelect]="true"
                                                    [enablePlaceholder]="true"
                                                    [enableAutoCountrySelect]="true"
                                                    [searchCountryFlag]="true"
                                                    [searchCountryField]="[
                                                      SearchCountryField.Iso2,
                                                      SearchCountryField.Name
                                                    ]"
                                                    [selectFirstCountry]="false"
                                                    [selectedCountryISO]="CountryISO.India"
                                                    [maxLength]="15"
                                                    [phoneValidation]="true"
                                                    [separateDialCode]="separateDialCode"
                                                    [numberFormat]="PhoneNumberFormat.National"
                                                    name="phone"
                                                    formControlName="phone"
                                                    (countryChange)="onCountryChangeTP($event)"
></ngx-intl-tel-input>
                                            </div>
                                            <p class="mb-0 meserr text-danger" *ngIf="phoneValidDivTP">
                                                This field cannot be empty
                                              </p>

                                              <p class="mb-0 meserr text-danger" *ngIf="phoneValidationCheckDivTP">
                                                Please enter the valid number
                                              </p>

                                        </div>

                                        
                                    <p class="mb-1 comm-12">All input fields are mandatory <span class="text-danger">*</span></p>
                                    <div class="justify-content-center align-items-center d-flex submit-button">
                                        <button class="button-primary w-100 trydox" id="myButton" type="submit"><span
                                                class="hideDownload">Submit</span> </button>
                                        <div id="loader" class="spinner-border text-white"
                                            style="display:none;position:absolute;" role="status">
                                            <span class="sr-only"></span>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div id="tallyDMShide" *ngIf="waitingFormSubmitted" class="d-flex  flex-column justify-content-center align-items-center"
                        style="height:363px">
                        <p class="common-para text-center">Thank you for reaching out. Someone from our team will
                            connect with you shortly! </p>
                        <img src="../../../assets/Image/tallysuccess.svg">
                        </div>

                    </div>



                </div>
            </div>
        </div>
    </div>
</section>
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { tallybackupHeroText } from 'src/constants/product-content';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { SEOService } from 'src/app/seo.service';
import { ZohoService } from 'src/app/zoho.service';
import * as AOS from 'aos';
import { EmailService } from 'src/app/email.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tally-backup',
  templateUrl: './tally-backup.component.html',
  styleUrls: ['./tally-backup.component.scss']
})
export class TallyBackupComponent implements OnInit {
  tallybackup_img:string="../../../../assets/Image/tallyBackup.svg"
  readonly tallybackupHeroText: typeof tallybackupHeroText = tallybackupHeroText;
  // isLoading = false;
  tallyBackupForm: FormGroup;
  firstNameValidDivTP: boolean = false;
  lastNameValidDivTP: boolean = false;
  emailValidDivEmptyTP: boolean = false;
  emailValidDivInvalidTP: boolean = false;
  phoneValidDivTP: boolean = false;
  phoneValidationCheckDivTP: boolean = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  separateDialCode!: false;
  PhoneNumberFormat = PhoneNumberFormat;
  waitingFormSubmitted: boolean = false;
  waitingFormShow: boolean = true;
  countryNameTP: string = 'India';
  dialCodeTP: number = 91;
  contactNumberTP: any;

  constructor(private fb: FormBuilder, private zohoService: ZohoService, private emailService: EmailService) {
    this.tallyBackupForm = this.fb.group({
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email,
        Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),]],
        phone: ['', [Validators.required, Validators.maxLength(15)]],
    });
    if (typeof window !== 'undefined') {
      this.SearchCountryField = SearchCountryField;
      this.CountryISO = CountryISO;
      this.PhoneNumberFormat = PhoneNumberFormat;
    }
  }
  ngOnInit(): void {
    this.emailService.headerOff.next(true);
    console.log("tally backup page");
    if (typeof window !== 'undefined') {
      AOS.init();
    }
    
    // throw new Error('Method not implemented.');
  }

  onCountryChangeTP(event: any) {
    this.countryNameTP = event.name;
    this.dialCodeTP = event.dialCode;
    this.contactNumberTP = event.e164Number;
    console.log('Selected Country ISO:', event.iso2);
    console.log('Selected Country Name:', this.countryNameTP);
    console.log('Selected Country Dial Code:', this.dialCodeTP);
  }

  resetFirstNameDivTP(event: Event) {
    this.firstNameValidDivTP = false;
  }

  resetLastNameDivTP(event: Event) {
    this.lastNameValidDivTP = false;
  }

  resetEmailDivTP(event: Event) {
    this.emailValidDivEmptyTP = false;
    this.emailValidDivInvalidTP = false;
  }

  resetPhoneDivTP(event: Event) {
    this.phoneValidDivTP = false;
    this.phoneValidationCheckDivTP = false;
  }

  onSubmitTallyBackup(event: Event): void {
    if(this.tallyBackupForm.get('firstname')?.hasError('required')) {
      this.firstNameValidDivTP = true;
    }
  
    if(this.tallyBackupForm.get('lastname')?.hasError('required')) {
      this.lastNameValidDivTP = true;
    }

    if(this.tallyBackupForm.get('email')?.hasError('required')) {
      this.emailValidDivEmptyTP = true;
    }
  
    if(this.tallyBackupForm.get('email')?.hasError('email')) {
      this.emailValidDivInvalidTP = true;
    }
  
    if(this.tallyBackupForm.get('email')?.hasError('pattern')) {
      this.emailValidDivInvalidTP = true;
    }

    if(this.tallyBackupForm.controls['phone'].invalid) {
      this.phoneValidationCheckDivTP = true;
      event?.preventDefault();
     }
  
    if(this.tallyBackupForm.get('phone')?.hasError('required')) {
      this.phoneValidDivTP = true;
      this.phoneValidationCheckDivTP = false;

      
    }
  
      if (this.tallyBackupForm.valid && !this.phoneValidationCheckDivTP) {
        console.log("submitted")
          this.waitingFormSubmitted = true;
          this.waitingFormShow = false;
          const phoneValueTP = this.tallyBackupForm.value.phone;
          //  contactNumber = `+${this.dialCode}${phoneValue}`;
          this.contactNumberTP = phoneValueTP.internationalNumber;
            console.log("contactNumber", this.contactNumberTP)
            console.log("dialCode", this.dialCodeTP)
            console.log("phoneValue", this.contactNumberTP)
            console.log("countryName", this.countryNameTP)
  
          /*
          payload for zoho service
        */
          const firstName = this.tallyBackupForm.value.firstname;
          const lastName = this.tallyBackupForm.value.lastname;
          const fullName = `${firstName} ${lastName}`;
          const email = this.tallyBackupForm.value.email;
          const WS_SubscriptionStatus = "";
          const leadSource = 'WS-backupPrelaunch';
          const address1 = '';
          const address2 = '';
          const phoneNo = this.contactNumberTP;
          const imgContent_security = '';
          const state = '';
          const country = this.countryNameTP;
          const orgName = this.tallyBackupForm.value.companyname;
          const companySize = '';
          const jobTitle = '';
          const salutation = '';
          const message1 = '';
          const message2 = '';
  
          /*
            function to send data to zoho service
          */
  
          this.zohoService.sendUserData(firstName, lastName, fullName, 
            email, 
            WS_SubscriptionStatus, 
            leadSource, 
            address1,
            address2,
            phoneNo,
            imgContent_security,
            state,
            country,
            orgName,
            companySize,
            jobTitle,
            salutation,
            message1,
            message2)
          .subscribe(
            (response) => {
              console.log('zoho lead sent:', response);
            },
            error => {
              console.error('Email Error:', error);
            }
          );
  
        }
  
        
      }
}

import { Component, Input } from '@angular/core';
import { ContentI} from 'src/app/interfaces/interfaces';
@Component({
  selector: 'app-right-image-left-text',
  templateUrl: './right-image-left-text.component.html',
  styleUrls: ['./right-image-left-text.component.scss']
})
export class RightImageLeftTextComponent {
@Input() imgUrl:string="";
@Input() content!:ContentI;
urlContains: any;
anchorTags: boolean = false;

ngOnInit() {
  if (typeof window !== 'undefined') {
    this.urlContains = window.location.href;
    if (this.urlContains.includes("download-dms-add-on?") && (this.content.heading.includes('STEP 1'))) {
      this.anchorTags = true;
    }
  }

}


}
